/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import InfoLiveComponent from './components/info-live.component'
import ModalComponent from './components/modal.component'
import HeaderService from './services/header.service'
import LayoutService from './services/layout.service'

document.addEventListener('DOMContentLoaded', () => {
    new HeaderService()
    new LayoutService()
    new ModalComponent()
    new InfoLiveComponent()
})
