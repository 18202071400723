import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.switcher_language()
        NavbarComponent.linkHover()
        NavbarComponent.addClassOnMenuItem()
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        $(window).on('load scroll', function () {
            const offset = 100 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()
            let hasBanner = false

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                hasBanner = true
            }

            // Hidden on scroll comportment
            if (
                scrollTop >= lastScrollTop &&
                scrollTop > navbar.outerHeight() &&
                !$(NavbarEnum.BODY).hasClass('menu-open')
            ) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $(NavbarEnum.LOGO)
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        //Open/Close menu
        $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
            $(NavbarEnum.BODY).toggleClass('menu-open')

            if (
                window.matchMedia('(max-width: 1001px)').matches &&
                $(NavbarEnum.BODY).hasClass('menu-open')
            ) {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            //Change logo on mobile
            if (window.matchMedia('(max-width: 650px)').matches) {
                if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                    NavbarComponent.changeLogoColor('classic')
                } else {
                    if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                        NavbarComponent.changeLogoColor('banner')
                    }
                }
            }

            //Hide ecomode
            $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')

            if (!$(NavbarEnum.BODY).hasClass('menu-open')) {
                $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
                $(NavbarEnum.BODY).removeClass('submenu-open')
            }
        })

        //Open/Close submenu
        if (window.matchMedia('(max-width: 999px)').matches) {
            $(NavbarEnum.BURGER_MENU_MORE).on('click', function (e) {
                e.preventDefault()
                $(this).nextAll(NavbarEnum.BURGER_SUBMENU).toggleClass('submenu-open')
                $(NavbarEnum.BODY).addClass('submenu-open')
            })
        }

        $(NavbarEnum.BURGER_MENU_PREV).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('submenu-open')
        })

        //Close submenu
        $(NavbarEnum.BURGER_SUBMENU_CLOSE).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('menu-open')
            if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                NavbarComponent.changeLogoColor('banner')
            } else {
                NavbarComponent.changeLogoColor('classic')
            }
        })
    }

    static switcher_language() {
        $(NavbarEnum.SWITCHER_LANG).on('click', () => {
            $(NavbarEnum.LIST_LANG).toggleClass('list-lang--open')
        })
    }

    static linkHover() {
        if (window.matchMedia('(min-width: 1001px)').matches) {
            $(NavbarEnum.MENU_ITEM).hover(
                function () {
                    //Display animation
                    $(NavbarEnum.NAVBAR).addClass('active')
                    $(this).find(NavbarEnum.MENU_ITEM_LINK).addClass('active-link')
                },
                function () {
                    $(NavbarEnum.NAVBAR).removeClass('active')
                    $(this).find(NavbarEnum.MENU_ITEM_LINK).removeClass('active-link')
                },
            )
        }
    }

    static addClassOnMenuItem() {
        const submenus = document.querySelectorAll('.menu__item__submenu')

        submenus.forEach((submenu) => {
            const items = submenu.querySelectorAll('.menu__item__submenu__content__item')
            for (const item of items) {
                if (
                    !item.classList.contains('is-thumb') &&
                    !item.classList.contains('is-accelerator')
                ) {
                    item.classList.add('first-list-item')
                    break
                }
            }
        })
    }
}
