export default class NavbarEnum {
    static BODY = 'body'
    static NAVBAR = '[data-element="navbar"]'
    static LOGO = '[data-element="navbar-logo"]'
    static ECOMODE = '[data-id="eco-bar"]'
    static BURGER_MENU_TRIGGER = '[data-trigger="burger-menu-toggle"]'
    static BURGER_MENU_MORE = '[data-trigger="burger-menu-more"]'
    static BURGER_MENU_PREV = '[data-trigger="burger-menu-prev"]'
    static BURGER_SUBMENU = '[data-element="burger-submenu"]'
    static SWITCHER_LANG = '[data-trigger="switcher-lang"]'
    static LIST_LANG = '[data-element="list-lang"]'
    static MENU_ITEM = '[data-element="menu-item"]'
    static MENU_ITEM_LINK = '[data-element="menu-item-link"]'
    static BURGER_SUBMENU_CLOSE = '[data-trigger="burger-menu-close"]'
}
