export default class InfoLiveComponent {
    constructor() {
        InfoLiveComponent.init()
    }

    static init() {
        // Initialize the info live panel
        InfoLiveComponent.setupToggle()
        InfoLiveComponent.setupCloseOnEscape()
        InfoLiveComponent.setupCloseOnClickOutside()
    }

    static setupToggle() {
        const toggleTriggers = document.querySelectorAll('[data-trigger="info-live-toggle"]')
        if (!toggleTriggers.length) return

        toggleTriggers.forEach((trigger) => {
            trigger.addEventListener('click', () => {
                const body = document.body
                const isOpen = body.classList.contains('info-live-open')

                if (isOpen) {
                    InfoLiveComponent.closePanel()
                } else {
                    InfoLiveComponent.openPanel()
                }
            })
        })
    }

    static setupCloseOnEscape() {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape' && document.body.classList.contains('info-live-open')) {
                InfoLiveComponent.closePanel()
            }
        })
    }

    static setupCloseOnClickOutside() {
        document.addEventListener('click', (e) => {
            if (!document.body.classList.contains('info-live-open')) return

            const infoLivePanel = document.querySelector('.info-live')
            const toggleTriggers = document.querySelectorAll('[data-trigger="info-live-toggle"]')
            const clickedOnTrigger = Array.from(toggleTriggers).some((trigger) =>
                trigger.contains(e.target),
            )

            if (!infoLivePanel) return

            // Check if click is outside the panel and not on any toggle button
            if (!infoLivePanel.contains(e.target) && !clickedOnTrigger) {
                InfoLiveComponent.closePanel()
            }
        })
    }

    static openPanel() {
        const body = document.body
        const toggleTriggers = document.querySelectorAll('[data-trigger="info-live-toggle"]')

        body.classList.add('info-live-open')
        toggleTriggers.forEach((trigger) => trigger.classList.add('is-active'))

        // Trigger a custom event that can be used by other components
        document.dispatchEvent(new CustomEvent('infoLiveOpened'))
    }

    static closePanel() {
        const body = document.body
        const toggleTriggers = document.querySelectorAll('[data-trigger="info-live-toggle"]')

        body.classList.remove('info-live-open')
        toggleTriggers.forEach((trigger) => trigger.classList.remove('is-active'))

        // Trigger a custom event that can be used by other components
        document.dispatchEvent(new CustomEvent('infoLiveClosed'))
    }
}
